import React, { useEffect, useState } from 'react';
import {
  Card,
  Row,
  Divider,
  Typography,
  Col,
  Descriptions,
  Form,
  Button,
  message,
  Alert,
  InputNumber,
  Collapse,
  Checkbox,
  Select,
  Modal,
  Input,
  DatePicker,
} from 'antd';
import { States, State, Liquidacion, Request } from 'sigt';
import { connect, useSelector } from 'react-redux';
import { useWindowDimensions } from '../../utils/hooks';
import { useParams, useHistory } from 'react-router';
import Table, { ColumnsType } from 'antd/lib/table';
import moment from 'moment';
import { CloudDownloadOutlined, CreditCardFilled, EditFilled, MinusCircleOutlined } from '@ant-design/icons';
import axios from 'axios';
import { changeRequestStatus, rebateRequest } from '../../redux/actions/requests';
import { cloneDeep, forEach, round } from 'lodash';
import { changeTaxData } from '../../redux/actions/taxes';
import MultipleTransactions from '../Forms/components/MultipleTransactions';
import MultipleWireTransfers from '../Forms/components/MultipleWireTransfers';
import Petro from '../Icons/Petro';
import { getCurrency } from '../../utils/currency';
const server = process.env.REACT_APP_SERVER_URL;

const PaySpecificTax: React.FC<PaySpecificTaxProps> = ({
  thm,
  requests,
  banks,
  institutions,
  auth,
  rebateRequest,
  changeRequestStatus,
  changeTaxData,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<any>({});
  const [credit, setCredit] = useState<any>({});
  const [conCredit, setConCredit] = useState<any[any]>([]);
  const [discount, setDiscount] = useState<boolean>(false);
  // const [days, setDays] = useState(0)
  const [downloading, setDownloading] = useState(false);
  const [files, setFiles] = useState<{ [P: string]: File } | null>(null);
  const petro = useSelector((state: State) => state.coin.petro) || 18000000;
  const [conRecargo, setConRecargo] = useState(false);
  const [conInteres, setConInteres] = useState(false);
  const [recargo, setRecargo] = useState(0);
  const [interes, setInteres] = useState(0);
  const [modifyModal, setModifyModal] = useState(false);

  const { width } = useWindowDimensions();
  const { id } = useParams<{ id: any }>();
  const history = useHistory();
  const [form] = Form.useForm();
  const [formModify] = Form.useForm();
  //a

  const canPay: number[] = [22, 25, 40, 41, 42, 53, 90, 98, 100, 104];
  const canModify: number[] = [90, 98];
  const canRecharge: number[] = [90, 98, 104];
  const canDiscount: number[] = [90, 35];
  const ramosBomberos: number[] = [236, 237, 238, 239, 240, 241, 242, 243, 244, 245, 246, 247, 441];
  const ramosPeajes: number[] = [525, 526, 527, 528];
  // const sedematBanks = [1, 2, 3, 14, 22, 19, 23, 25, 11, 12, 35, 6];
  // const canReduce: number[] = [22, 25, 40, 41];
  const selectedReq = requests.find((r) => r.id === parseInt(id || '0'));
  const selectedInstitution = institutions?.find((i) => i.id === 9);

  const aforos = useSelector((state: State) => state.ae.activities);

  const formatCurrency = (number: number) => new Intl.NumberFormat('de-DE').format(number);
  const firstLetterMayus = (s: string) => s.charAt(0).toUpperCase() + s.slice(1);

  useEffect(() => {
    if (!selectedReq) history.push('/dashboard');
    else {
      console.log(selectedReq, 'Selected r');
      fetchCredit(selectedReq.id);
      if (selectedReq?.contribuyente?.id) fetchConCredit(selectedReq?.contribuyente?.id);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (selectedReq) {
      let monto = Number(selectedReq?.monto) || Number(selectedReq?.montoPetro) * petro;
      let recargo = 0;
      let interes = 0;
      if (conInteres === true) {
        if (selectedReq?.multas) {
          interes = selectedReq.multas[0]?.interes || 0;
        } else {
          interes = selectedReq?.liquidaciones[0]?.interes || 0;
        }
      }
      if (conRecargo === true) {
        recargo = round(((monto || 0) + interes) * 0.1, 2);
      }
      setRecargo(recargo);
      setInteres(interes);
    }
  }, [selectedReq, conRecargo, conInteres]);

  const fetchCredit = async (id) => {
    try {
      const response = await axios.get(`${server}/settlements/request/credit/${id}`, {
        headers: { Authorization: `Bearer ${auth.token}` },
      });
      setCredit(response.data?.credit);
    } catch (e) {
      // message.error(e.response?.data?.message || 'Error al conseguir los contribuyentes');
    }
  };

  const fetchConCredit = async (id) => {
    setLoading(true);
    try {
      const response = await axios.get(`${server}/settlements/contributor/credit/${id}`, {
        headers: { Authorization: `Bearer ${auth.token}` },
      });
      setConCredit(response.data.credit);
    } catch (e) {
      message.error(e.response?.data?.message || 'Error al conseguir las compensaciones');
    } finally {
      setLoading(false);
    }
  };

  const requestCredit = async () => {
    try {
      const newCredit = { id: credit?.id_credito_fiscal, available: Number(credit?.credito), state: 'enproceso' };
      const response = await axios.post(
        `${server}/settlements/credit/update`,
        { ...newCredit },
        { headers: { Authorization: `Bearer ${auth.token}` } }
      );
      setCredit(response.data?.credit);
      message.success('Compensación solicitada exitosamente');
    } catch (e) {
      message.error(e.response?.data?.message || 'Error al solicitar la compensación');
    }
  };

  const modifyReceipt = async () => {
    try {
      let values = await formModify.validateFields();
      values = {...values, ute: values.montoPetro, bs: values.monto}
      const response = await axios.post(
        `${server}/settlements/receipt/${selectedReq?.id}/custom`,
        values,
        { headers: { Authorization: `Bearer ${auth.token}` } }
      );

      message.success('Modificación solicitada exitosamente');
      if (response.data.url) {
        const win = window.open(response.data.url, '_blank');
        win?.focus();
      }
    } catch (e) {
      message.error(e.response?.data?.message || 'Error al solicitar la modificación');
    }
  };

  const pay = async () => {
    let values = await form.validateFields();
    Object.keys(values).forEach((k) => {
      if (moment.isMoment(values[k])) {
        values[k] = values[k].toISOString();
      }
    });
    let pagos = Array.isArray(form.getFieldValue('pagos')) ? cloneDeep(form.getFieldValue('pagos')) || [] : [];
    const checkF = (i) => {
      return files ? files.hasOwnProperty(`Comprobante${i + 1}`) : false;
    };
    let notP: Array<any> = [];
    if (pagos && pagos?.length > 0) {
      pagos.map((p, i) => {
        p.i = i;
        p.idInstitucionBanco = p.destino;
        p.destino = banks?.find(
          (b) => b.id === selectedInstitution?.cuentasBancarias?.find((c) => c.id === p.destino)?.banco
        )?.id;
        return p;
      });
      let filterP = pagos.filter((p) => p.metodoPago !== 'PUNTO DE VENTA');
      if (filterP && filterP.length > 0) {
        filterP.map((p, i) => {
          p.check = checkF(p.i);
          notP.push(p);
        });
      }
    }
    const hasAllMandatoryTakings = notP && notP?.length > 0 ? !notP.some((p, i) => p.check === false) : true;

    if (!hasAllMandatoryTakings && auth?.user?.tipoUsuario === 4) {
      return message.error('Debe ingresar un comprobante por cada pago');
    }
    pagos
      .filter((p) => p)
      .forEach((p) => {
        Object.keys(p).forEach((k) => {
          if (moment.isMoment(p[k])) {
            p[k] = p[k].toISOString();
          }
        });
      });
    const montoAPagar = round(
      +parseFloat(selectedReq?.monto || (selectedReq?.liquidaciones[0]?.idRamo === 9 ? String(selectedReq?.liquidaciones[0]?.desglose?.reduce((prev, current) => prev + Number(current.montoCobrado), 0)) : ((+(selectedReq?.montoPetro || 0) * petro) as unknown as string)) || '0').toFixed(2) +
        recargo +
        interes,
      2
    );
    let ingresadoCompensacion = values.compensaciones
      ? conCredit
          ?.filter((c) => values?.compensaciones?.find((com) => com === c.id_credito_fiscal))
          ?.reduce((prev, current) => prev + current.disponible, 0)
      : 0;
    if (pagos.map((p) => p.costo).reduce((c, v) => c + v, 0) + Number(ingresadoCompensacion) < montoAPagar)
      return message.error('La suma de los montos de las transacciones debe ser igual o mayor al total de la liquidación');
    setLoading(true);
    const complete = message.loading('Creando pago para la declaración de impuestos');
    try {
      const procedimiento = {
        pagos,
        interesMoratorio: (selectedReq?.interesMoratorio || 0) * petro,
        conRecargo: conRecargo,
        recargo: recargo,
        conInteres: conInteres,
        interes: interes,
        credit:
          conCredit && conCredit?.length > 0
            ? conCredit.filter((c) => values?.compensaciones?.find((com) => com === c.id_credito_fiscal))
            : null,
      };
      const response = await axios.put(
        `${server}/settlements/${id}/payment`,
        { procedimiento },
        { headers: { Authorization: `Bearer ${auth.token}` } }
      );
      const fd = new FormData();
      if (files) Object.keys(files).forEach((k) => fd.append('recaudos', files[k]));
      const resp = await axios.post(`${server}/uploads/special/support/${id}`, fd, {
        headers: { Authorization: `Bearer ${auth.token}` },
      });
      changeRequestStatus(response.data.solicitud.estado, parseInt(id || '0'));
      message.success('Pago registrado de manera exitosa');
      auth.user?.tipoUsuario === 4 ? history.push('/dashboard/impuestos/pagar') : history.push('/dashboard/impuestos/pendientes');
      response.data.solicitud.liquidaciones.forEach((item) => {
        const data: Liquidacion = {
          id: item.id,
          fecha: item.fecha,
          ramo: item.ramo,
          monto: item.monto,
          montoPetro: item.montoPetro,
          aprobado: response.data.solicitud.aprobado,
          pagado: response.data.solicitud.pagado,
          recibo: item.recibo,
          certificado: item.certificado,
          estado: response.data.solicitud.estado,
        };
        changeTaxData(data.id, data);
      });
      if (response.data.solicitud.recibo) {
        const win = window.open(response.data.solicitud.recibo, '_blank');
        win?.focus();
      }
    } catch (e) {
      if (e.response?.data?.message) {
        message.error(
          (e.response?.data?.message as string).includes('referencia')
            ? 'La referencia bancaria ya ha sido utilizada.'
            : e.response?.data?.message
        );
      }
    } finally {
      setLoading(false);
      complete();
    }
  };

  const getFineTotals = () =>
    parseFloat(
      selectedReq?.multas
        ?.map((r) => +r.monto || +r.montoPetro * petro)
        .reduce((c, v) => c + v, 0)
        .toFixed(2) || '0'
    );

  const getLiqsTotal = () =>
    parseFloat(
      selectedReq?.liquidaciones
        .map((l) => +l.monto || +l.montoPetro * petro)
        .reduce((c, v) => c + v, 0)
        .toFixed(2) || '0'
    );

  const getLiqsAETotal = () =>
    parseFloat(
      selectedReq?.liquidaciones
        .map((l) => (l.ramo?.includes('ACTIVIDADES ECONOMICAS') ? +l.montoPetro * petro : 0))
        .reduce((c, v) => c + v, 0)
        .toFixed(2) || '0'
    );

    const mesesNumerico = {
      enero: 0,
      febrero: 1,
      marzo: 2,
      abril: 3,
      mayo: 4,
      junio: 5,
      julio: 6,
      agosto: 7,
      septiembre: 8,
      octubre: 9,
      noviembre: 10,
      diciembre: 11,
    };
  
    const getTaxName = (month, year, ramo) => {
      const unoPorCienDate = moment().year(2022).month(2).date(31);
      const dosPorCienDate = moment().year(2023).month(10).date(8);
      const taxDate = moment().year(year).month(mesesNumerico[month]).date(1);;
      let taxName = ramo;
      if(['Impuesto 1x1000','Impuesto 1X1000'].includes(ramo)){
        if(taxDate.isBefore(unoPorCienDate)){
          taxName = 'Impuesto 1X100';
        }
        else if(taxDate.isBefore(dosPorCienDate)){
          taxName = 'Impuesto 2X100';
        }
      }
      return taxName; 
    }

  const columns: ColumnsType<any> = [
    {
      title: 'Tipo de Liquidación',
      dataIndex: 'ramo',
      render: (ramo, record) => <span>{ramo ? (getTaxName(record.fecha.month, record.fecha.year, ramo)) : 'LIQUIDACIÓN SIN RAMO'}</span>,
    },
    {
      title: 'I.V.A. Retenido',
      dataIndex: 'ramo',
      render: (ramo, record) =>
        ramo === 'SERVICIOS MUNICIPALES' ? <b>{record.esAgenteSENIAT ? '75%' : '0%'}</b> : <span>No Aplica</span>,
    },
    {
      title: 'Fecha',
      dataIndex: 'fecha',
      render: (fecha) =>
        fecha ? (
          <span>
            {firstLetterMayus(fecha.month)} {fecha.year}
          </span>
        ) : (
          <span>Sin Fecha</span>
        ),
    },
    {
      title: `Monto (${getCurrency()})`,
      dataIndex: 'montoPetro',
      align: 'center',
      render: (_petro, record) =>
        _petro ? (
          <span>
            {Number(_petro).toFixed(8)} <Petro />
          </span>
        ) : (
          '-'
        ),
    },
    {
      title: 'Monto en Bs.',
      dataIndex: 'monto',
      render: (monto, record) => <span>Bs. {formatCurrency(+(+monto || record.montoPetro * petro).toFixed(2))}</span>,
    },
  ];

  const fineColumns: ColumnsType<any> = [
    {
      title: 'Motivo',
      dataIndex: 'descripcion',
      render: (desc) => <span>{desc || 'MULTA VIGENTE'}</span>,
    },
    {
      title: 'Fecha',
      dataIndex: 'fecha',
      render: (fecha) => (
        <span>
          {firstLetterMayus(fecha.month)} {fecha.year}
        </span>
      ),
    },
    {
      title: `Monto (${getCurrency()})`,
      dataIndex: 'montoPetro',
      align: 'center',
      render: (_petro, record) =>
        _petro ? (
          <span>
            {Number(_petro).toFixed(8)} <Petro />
          </span>
        ) : (
          '-'
        ),
    },
    {
      title: 'Monto en Bs.',
      dataIndex: 'monto',
      render: (monto, record) => <span>Bs. {formatCurrency(+(+monto || record.montoPetro * petro).toFixed(2))}</span>,
    },
  ];

  const AEcolumns: ColumnsType<any> = [
    {
      title: 'Aforo',
      dataIndex: 'aforo',
      width: '40%',
      render: (id) => <span>{aforos.find((ae) => ae.id === id)?.descripcion}</span>,
    },
    {
      title: 'Alicuota',
      dataIndex: 'aforo',
      key: 'alicuota',
      render: (id) => <span>{aforos.find((ae) => ae.id === id)?.alicuota} %</span>,
    },
    {
      title: 'Monto Declarado',
      dataIndex: 'montoDeclarado',
      render: (md) => <span>Bs. {formatCurrency(+md)}</span>,
    },
    {
      title: 'Impuestos',
      dataIndex: 'montoCobrado',
      render: (mc) => <span>Bs. {formatCurrency(+mc)}</span>,
    },
  ];

  const IUcolumns: ColumnsType<any> = [
    {
      title: 'Código Catastral',
      dataIndex: 'codCat',
      width: '40%',
      render: (codCat) => (codCat ? <span>{codCat}</span> : <span>Inmueble sin Información Catastral</span>),
    },
    {
      title: 'Monto a Pagar',
      dataIndex: 'monto',
      render: (md) => <span>Bs. {formatCurrency(+md.toFixed(2))}</span>,
    },
  ];

  // const handleDiscount = (daysNumber) => {
  //   setDays(daysNumber);
  // };

  const downloadTicket = async (id: number) => {
    if (selectedReq?.referenciaMunicipal) {
      if (!downloading) {
        setDownloading(true);
        const complete = message.loading('Generando Recibo...');
        try {
          const response = await axios.post(`${server}/settlements/receipt/${id}/application`, null, {
            headers: { Authorization: `Bearer ${auth.token}` },
          });
          message.success('Recibo generado de manera exitosa.');
          window.open(response.data.url, '_blank');
        } catch (e) {
          message.error('Error al generar Recibo para la solicitud.');
        } finally {
          setDownloading(false);
          complete();
        }
      }
    } else {
      if (!downloading) {
        setDownloading(true);
        const complete = message.loading('Generando Timbre Fiscal...');
        try {
          const response = await axios.post(`${server}/settlements/receipt/${id}/application`, null, {
            headers: { Authorization: `Bearer ${auth.token}` },
          });
          message.success('Timbre Fiscal generado de manera exitosa.');
          window.open(response.data.url, '_blank');
        } catch (e) {
          message.error('Error al generar Timbre Fiscal para la solicitud.');
        } finally {
          setDownloading(false);
          complete();
        }
      }
    }
  };

  const applyDiscount = async () => {
    const { rebaja } = await form.validateFields();
    setLoading(true);
    const complete = message.loading('Creando pago para la declaración de impuestos');
    try {
      const solicitud = { montoRebajado: +Number(rebaja / petro).toFixed(8) };
      const response = await axios.patch(
        `${server}/settlements/rebate/${selectedReq?.id}`,
        { solicitud },
        { headers: { Authorization: `Bearer ${auth.token}` } }
      );
      message.success(response?.data?.message || 'Rebaja aplicada de manera exitosa!');
      rebateRequest(response.data.solicitud);
      history.push('/dashboard/impuestos/pendientes');
    } catch (e) {
      message.error(e.response?.data?.message ? e.response.data.message : 'Error al aplicar rebaja');
    } finally {
      setLoading(false);
      complete();
    }
  };

  // const reduceAmount = (record) => record ? (record.interesMoratorio - (days)) : 0;

  const AEs = selectedReq?.liquidaciones.filter((e) => (e.ramo?.includes('Impuesto 2x100') || e.ramo?.includes('Impuesto 1x100') || e.ramo?.includes('Impuesto 1x1000') || e.ramo?.includes('Impuesto 1X1000')) && e.desglose);
  const IUs = selectedReq?.liquidaciones.filter((e) => e.ramo?.includes('PROPIEDAD INMOBILIARIA') && e.desglose);

  const getPanelSubtitle = (month, year) => (
    <span>
      {firstLetterMayus(month)} {year}
    </span>
  );

  return (
    <Card
      style={{ height: '100%' }}
      title='Pagar Impuestos'
      bodyStyle={{ height: 'calc(100% - 88px)', overflowY: 'scroll', overflowX: 'hidden', padding: '0px 40px' }}
      headStyle={{ height: 64, backgroundColor: thm.primaryColor, padding: width < 992 ? '0 10px' : '0 20px', color: 'white' }}
    >
      <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 10 }}>
        <Typography.Text strong>Razón Social: {selectedReq?.contribuyente?.razonSocial}</Typography.Text>
        <Typography.Text strong>
          Documento: {selectedReq?.tipoDocumento}-{selectedReq?.documento}
          {/* {selectedReq?.referenciaMunicipal && (
            <React.Fragment>
              <br />
              Documento de Identidad: {selectedReq?.referenciaMunicipal}
            </React.Fragment>
          )} */}
        </Typography.Text>
      </div>
      {selectedReq?.tipo === 'RETENCION' && (
        <Alert
          banner
          type='warning'
          message='Una vez declarada la retención debe cancelarla de inmediato, de no hacerlo puede estar expuesto a las multas correspondientes.'
        />
      )}
      {selectedReq?.estado === 'finalizado' && (
        <>
          <Row gutter={24}>
            <Divider orientation='left'>
              <Typography.Title level={4} style={{ marginLeft: 0 }}>
                {selectedReq?.referenciaMunicipal ? 'Comprobante de Pago' : 'Timbre Fiscal'}
              </Typography.Title>
            </Divider>
            <Col>
              <Button
                type='primary'
                style={{ marginBottom: 10 }}
                loading={downloading}
                icon={<CloudDownloadOutlined />}
                onClick={() => downloadTicket(selectedReq?.id || 0)}
              >
                {selectedReq?.referenciaMunicipal ? 'Descargar Comprobante de Pago' : 'Descargar Timbre Fiscal'}
              </Button>
            </Col>
            {canModify.includes(auth.user?.institucion?.cargo?.id || 0) &&
            <Col>
              <Button
                type='primary'
                style={{ marginBottom: 10 }}
                loading={downloading}
                icon={<CloudDownloadOutlined />}
                onClick={() => setModifyModal(true)}
              >
                {'Modificar Comprobante'}
              </Button>
            </Col>}
          </Row>
        </>
      )}
      <Row gutter={24}>
        <Divider orientation='left'>
          <Typography.Title level={4} style={{ marginLeft: 0 }}>
            Declaraciones Presentadas
          </Typography.Title>
        </Divider>
        <Col span={24}>
          <Table
            columns={columns}
            dataSource={selectedReq?.liquidaciones.map((l, key) => {
              l.key = key;
              return l;
            })}
            bordered
            pagination={false}
          />
        </Col>
        <Col xl={12} xs={0} />
        <Col xl={12} xs={24}>
          <Descriptions bordered>
            <Descriptions.Item label='Total'>Bs. {formatCurrency(getLiqsTotal())}</Descriptions.Item>
          </Descriptions>
        </Col>
        {(selectedReq?.multas?.length || -1) > 0 && (
          <>
            <Divider orientation='left' style={{ marginLeft: 0 }}>
              <Typography.Title level={4}>Multas Pendientes</Typography.Title>
            </Divider>
            <Col span={24}>
              <Table
                columns={fineColumns}
                dataSource={
                  selectedReq?.multas?.map((r, key) => {
                    r.key = key;
                    return r;
                  }) || []
                }
                bordered
                pagination={false}
              />
            </Col>
            <Col xl={12} xs={0} />
            <Col xl={12} xs={24}>
              <Descriptions bordered>
                <Descriptions.Item label='Total'>Bs. {formatCurrency(getFineTotals())}</Descriptions.Item>
              </Descriptions>
            </Col>
          </>
        )}
        {AEs && AEs.length > 0 && (
          <>
            <Divider orientation='left' style={{ marginLeft: 0 }}>
              <Typography.Title level={4}>Declaración de Actividades Económicas</Typography.Title>
            </Divider>
            <Collapse style={{ width: '100%' }} accordion>
              {AEs.map((ae, key) => (
                <Collapse.Panel key={key} header={getPanelSubtitle(ae.fecha.month, ae.fecha.year)}>
                  <Row>
                    <Col span={24}>
                      <Table columns={AEcolumns} pagination={false} rowKey='aforo' bordered dataSource={ae.desglose} />
                    </Col>
                  </Row>
                </Collapse.Panel>
              ))}
            </Collapse>
          </>
        )}
        {IUs && IUs.length > 0 && (
          <>
            <Divider orientation='left' style={{ marginLeft: 0 }}>
              <Typography.Title level={4}>Inmuebles Urbanos</Typography.Title>
            </Divider>
            <Collapse style={{ width: '100%' }} accordion>
              {IUs.map((iu, key) => (
                <Collapse.Panel key={key} header={getPanelSubtitle(iu.fecha.month, iu.fecha.year)}>
                  <Table columns={IUcolumns} pagination={false} rowKey='codCat' bordered dataSource={iu.desglose} />
                </Collapse.Panel>
              ))}
            </Collapse>
          </>
        )}
        {selectedReq?.interesMoratorio && selectedReq.interesMoratorio > 0 && (
          <>
            <Divider orientation='left' style={{ marginLeft: 0 }}>
              <Typography.Title level={4}>Intereses Moratorios</Typography.Title>
            </Divider>
            <Col span={24}>
              <Descriptions bordered>
                <Descriptions.Item label='Total de Intereses'>
                  Bs. {formatCurrency(+(selectedReq.interesMoratorio * petro).toFixed(2))}
                </Descriptions.Item>
              </Descriptions>
            </Col>
          </>
        )}
        {/* {canReduce.includes(auth.user?.institucion?.cargo?.id || 0) && 
        <>
          <Divider orientation='left' style={{ marginLeft: 0 }}>
            <Typography.Title level={4}>Rebaja de Intereses Moratorios</Typography.Title>
          </Divider>
          <Col span={24} style={{marginTop:'20px'}}>
            <Slider onChange={(e)=> handleDiscount(e)} marks={{0:'0 días', 5:'5 días'}} style={{width:'50%'}} max={5} min={0} />
          </Col>
        </>} */}
        <Divider orientation='left' style={{ marginLeft: 0 }}>
          <Typography.Title level={4}>Total a Pagar</Typography.Title>
        </Divider>
        <Col span={24}>
          <Descriptions bordered>
            <Descriptions.Item label='Total'>
              Bs.{' '}
              {formatCurrency(
                +parseFloat(
                  selectedReq?.monto || (selectedReq?.liquidaciones[0]?.idRamo === 9 ? String(selectedReq?.liquidaciones[0]?.desglose?.reduce((prev, current) => prev + Number(current.montoCobrado), 0)) : ((+(selectedReq?.montoPetro || 0) * petro) as unknown as string)) || '0'
                ).toFixed(2)
              )}
            </Descriptions.Item>
          </Descriptions>
        </Col>
        {credit && credit?.estado !== 'rechazado' && (
          <>
            <Divider orientation='left' style={{ marginLeft: 0 }}>
              <Typography.Title level={4}>Compensación</Typography.Title>
            </Divider>
            <Col span={24}>
              <Descriptions>
                {
                  <Descriptions.Item label='Disponible por Compensación'>
                    Bs. {formatCurrency(parseFloat(!credit?.estado ? credit?.credito || '0' : credit?.disponible || '0'))}
                  </Descriptions.Item>
                }
              </Descriptions>
            </Col>
            <Col>
              {credit?.estado === null && (
                <Button type='primary' onClick={() => requestCredit()}>
                  Solicitar Compensación
                </Button>
              )}
            </Col>
          </>
        )}
      </Row>
      {canDiscount.includes(auth.user?.institucion?.cargo?.id || 0) &&
      selectedReq?.liquidaciones.some(
        (l) => l.ramo === 'ACTIVIDADES ECONOMICAS COMERCIALES, INDUSTRIALES, DE SERVICIO Y SIMILARES'
      ) &&
      !selectedReq?.rebajado ? (
        <>
          {discount ? (
            <>
              <Divider orientation='left' style={{ marginLeft: -10 }}>
                <Typography.Title level={4}>Rebaja para Liquidación de Actividades Económicas</Typography.Title>
              </Divider>
              <Form
                layout='vertical'
                style={{ marginTop: 20 }}
                form={form}
                initialValues={{ metodoPago: 'TRANSFERENCIA' }}
                onValuesChange={(c, v) => {
                  form.setFieldsValue(v);
                  setData(v);
                }}
              >
                <Row>
                  <Col>
                    <Form.Item
                      label='Monto de Rebaja'
                      name='rebaja'
                      rules={[
                        { required: true, message: 'Por favor ingrese el monto de la rebaja.' },
                        {
                          validator: (rule, value) =>
                            value && getLiqsAETotal() < value
                              ? Promise.reject(
                                  'El monto de la rebaja no puede ser mayor al monto total de las Actividades Economicas.'
                                )
                              : Promise.resolve(),
                        },
                      ]}
                    >
                      <InputNumber
                        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        parser={(value) => (value ? value.replace(/Bs\s?|(,*)/g, '') : '')}
                        min={0}
                        step={1}
                        style={{ width: '100%' }}
                        placeholder='Monto'
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
              <Col span={24}>
                <Descriptions column={1} bordered>
                  <Descriptions.Item span={1} label='Monto Total de Actividades Economicas'>
                    Bs. {formatCurrency(getLiqsAETotal())}
                  </Descriptions.Item>
                  <Descriptions.Item span={1} label='Monto Despues de Rebaja'>
                    Bs. {formatCurrency(getLiqsAETotal() - (form.getFieldValue('rebaja') || 0))}
                  </Descriptions.Item>
                  <Descriptions.Item span={1} label='Monto Total de liquidacion despues de Rebaja'>
                    Bs.{' '}
                    {formatCurrency(
                      getLiqsTotal() +
                        getFineTotals() +
                        +(selectedReq?.interesMoratorio || 0) -
                        (form.getFieldValue('rebaja') || 0)
                    )}
                  </Descriptions.Item>
                </Descriptions>
              </Col>
              <Row gutter={12} style={{ marginTop: 10, display: 'flex', justifyContent: 'space-between' }}>
                <Col>
                  <Button onClick={() => history.goBack()}>Atrás</Button>
                </Col>
                <Col>
                  <Button
                    type='primary'
                    loading={loading}
                    style={{ marginTop: 15 }}
                    onClick={() => applyDiscount()}
                    icon={<MinusCircleOutlined />}
                  >
                    Aplicar Rebaja
                  </Button>
                </Col>
              </Row>
            </>
          ) : (
            <Row gutter={12} style={{ marginTop: 10, display: 'flex', justifyContent: 'space-between' }}>
              <Col>
                <Button onClick={() => history.goBack()}>Atrás</Button>
              </Col>
              {selectedReq.liquidaciones?.some((l) => l.ramo?.includes('ACTIVIDADES ECONOMICAS')) && (
                <Col>
                  <Button type='primary' onClick={() => setDiscount(true)} icon={<MinusCircleOutlined />}>
                    Rebajar Liquidación AE
                  </Button>
                </Col>
              )}
            </Row>
          )}
        </>
      ) : (
        !(auth.user?.tipoUsuario === 4 || canPay.includes(auth.user?.institucion?.cargo?.id || 0)) && (
          <Row gutter={12} justify='start' style={{ marginTop: 10 }}>
            <Col>
              <Button onClick={() => history.goBack()}>Atrás</Button>
            </Col>
          </Row>
        )
      )}
      {(auth.user?.tipoUsuario === 4 || canPay.includes(auth.user?.institucion?.cargo?.id || 0)) &&
        selectedReq?.estado === 'ingresardatos' && (
          <>
            <Divider orientation='left' style={{ marginLeft: -10 }}>
              <Typography.Title level={4}>Datos de Pago</Typography.Title>
            </Divider>
            {([29, 48].includes(selectedReq.liquidaciones[0]?.idRamo || 0) ||
              ['REPAROS FISCALES POR AE', 'MULTAS'].includes(selectedReq.liquidaciones[0]?.ramo || '') ||
              (selectedReq.multas?.length || 0) > 0) && (
              <Row>
                <Checkbox checked={conRecargo} onChange={(e) => setConRecargo(!conRecargo)}>
                  Incluir Tasa de Recargo
                </Checkbox>
              </Row>
            )}
            {(selectedReq.multas?.length || 0) > 0 && (
              <Row>
                <Checkbox checked={conInteres} onChange={(e) => setConInteres(!conInteres)}>
                  Incluir Tasa de Interes
                </Checkbox>
              </Row>
            )}

            <Typography.Text strong>
              La liquidación de impuesto tiene un total de de Bs.{' '}
              {formatCurrency(
                +(
                  parseFloat(selectedReq?.monto || (selectedReq?.liquidaciones[0]?.idRamo === 9 ? String(selectedReq?.liquidaciones[0]?.desglose?.reduce((prev, current) => prev + Number(current.montoCobrado), 0)) : ((+(selectedReq?.montoPetro || 0) * petro) as unknown as string)) || '0') +
                  recargo +
                  interes
                ).toFixed(2)
              )}
              . El monto de la(s) transaccion(es) debe ser igual o mayor al total de pagar.
            </Typography.Text>
            <Form
              layout='vertical'
              style={{ marginTop: 20 }}
              form={form}
              initialValues={{ metodoPago: 'TRANSFERENCIA' }}
              onValuesChange={(c, v) => {
                form.setFieldsValue(v);
                setData(v);
              }}
            >
              {credit && credit?.length > 0 && (
                <>
                  <Divider orientation='left' style={{ marginLeft: -20 }}>
                    <Typography.Text ellipsis strong style={{ marginLeft: 3 }}>
                      Compensaciones Disponibles
                    </Typography.Text>
                  </Divider>
                  <Col xs={24} xl={20}>
                    <Form.Item rules={[{ required: false }]} name='compensaciones'>
                      <Select mode='multiple' placeholder='Compensaciones Disponibles'>
                        {credit?.map((c) => (
                          <Select.Option value={c.id_credito_fiscal} key={c.id_credito_fiscal}>
                            Nro {c.resolucion} - Bs. {c.disponible}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </>
              )}
              {width >= 992 && <Col span={16} />}
              {auth.user?.tipoUsuario !== 4 ? (
                <MultipleTransactions
                  selectedReqType={selectedReq?.tipo}
                  selectedInstitution={selectedInstitution}
                  form={form}
                  data={data}
                  maxCred={parseFloat(selectedReq?.creditoFiscal || '0')}
                  maxCredR={parseFloat(selectedReq?.creditoFiscalRetencion || '0')}
                  files={files}
                  setFiles={setFiles}
                  percentTax={selectedReq.liquidaciones?.some((l) => (l.ramo?.includes('Impuesto 1X1000') || l.ramo?.includes('Impuesto 2X100') || l.ramo?.includes('Impuesto 1X100')) )}
                  mineralTax={selectedReq.liquidaciones?.some((l) => (l.idRamo === 391 || l.idRamo === 392 || l.idRamo === 393 || l.idRamo === 491))}
                  bomberos={selectedReq.liquidaciones?.some((l) => ramosBomberos.includes(l.idRamo || 0))}
                  peajes={selectedReq.liquidaciones?.some((l) => ramosPeajes.includes(l.idRamo || 0))}
                />
              ) : (
                <MultipleWireTransfers selectedInstitution={selectedInstitution} form={form} files={files} setFiles={setFiles} 
                percentTax={selectedReq.liquidaciones?.some((l) => (l.ramo?.includes('Impuesto 1X1000') || l.ramo?.includes('Impuesto 2X100') || l.ramo?.includes('Impuesto 1X100')))} 
                mineralTax={selectedReq.liquidaciones?.some((l) => (l.idRamo === 391 || l.idRamo === 392 || l.idRamo === 393 || l.idRamo === 491))}
                bomberos={selectedReq.liquidaciones?.some((l) => ramosBomberos.includes(l.idRamo || 0))}
                peajes={selectedReq.liquidaciones?.some((l) => ramosPeajes.includes(l.idRamo || 0))}
                />
              )}
            </Form>
            <Row>
              <Divider orientation='left' style={{ marginLeft: 0 }}>
                <Typography.Title level={4}>Total Ingresado</Typography.Title>
              </Divider>
              <Col xl={24} xs={24}>
                <Descriptions bordered>
                  <Descriptions.Item label='Total Ingresado'>
                    Bs.{' '}
                    {formatCurrency(
                      (data.pagos
                        ?.filter((p) => p)
                        ?.map((p) => p.costo)
                        ?.reduce((c, v) => c + v, 0) || 0) +
                        Number(
                          form.getFieldValue('compensaciones')
                            ? credit
                                ?.filter((c) => form.getFieldValue('compensaciones')?.find((com) => com === c.id_credito_fiscal))
                                ?.reduce((prev, current) => prev + current.disponible, 0)
                            : 0
                        )
                    )}
                  </Descriptions.Item>
                </Descriptions>
              </Col>
              <Divider orientation='left' style={{ marginLeft: 0 }}>
                <Typography.Title level={4}>Monto Restante</Typography.Title>
              </Divider>
              <Col xl={24} xs={24}>
                <Descriptions bordered>
                  <Descriptions.Item label='Total Restante' style={{ color: 'red' }}>
                    Bs.{' '}
                    {formatCurrency(
                      ((data.pagos
                        ?.filter((p) => p)
                        ?.map((p) => p.costo)
                        ?.reduce((c, v) => c + v, 0) || 0) +
                        Number(
                          form.getFieldValue('compensaciones')
                            ? credit
                                ?.filter((c) => form.getFieldValue('compensaciones')?.find((com) => com === c.id_credito_fiscal))
                                ?.reduce((prev, current) => prev + current.disponible, 0)
                            : 0
                        ) -
                        +parseFloat(
                          selectedReq?.monto || (selectedReq?.liquidaciones[0]?.idRamo === 9 ? String(selectedReq?.liquidaciones[0]?.desglose?.reduce((prev, current) => prev + Number(current.montoCobrado), 0)) : ((+(selectedReq?.montoPetro || 0) * petro) as unknown as string)) || '0'
                        ).toFixed(2)) *
                        -1
                    )}
                  </Descriptions.Item>
                </Descriptions>
              </Col>
            </Row>
            <Row gutter={12} style={{ marginTop: 10, display: 'flex', justifyContent: 'space-between' }}>
              <Col>
                <Button onClick={() => history.goBack()}>Atrás</Button>
              </Col>
              <Col>
                {selectedReq?.estado === 'ingresardatos' && (
                  <Button
                    type='primary'
                    loading={loading}
                    style={{ marginTop: 15 }}
                    onClick={() => pay()}
                    icon={<CreditCardFilled />}
                  >
                    Pagar
                  </Button>
                )}
              </Col>
            </Row>
          </>
        )}
      {selectedReq?.estado !== 'ingresardatos' && (
        <Row gutter={12} style={{ marginTop: 10, display: 'flex', justifyContent: 'space-between' }}>
          <Col>
            <Button onClick={() => history.goBack()}>Atrás</Button>
          </Col>
        </Row>
      )}
      <Modal
        visible={modifyModal}
        onCancel={() => setModifyModal(false)}
        title='Modificar Comprobante'
        width={width < 992 ? '100%' : '40%'}
        okButtonProps={{ icon: <EditFilled />, title: 'Modificar', loading }}
        onOk={() => modifyReceipt()}
      >
        <Form layout='vertical' initialValues={{...selectedReq, subtotal:selectedReq?.monto, fecha: moment(selectedReq?.fecha), pagos: selectedReq?.pagos?.map((p) => {p.fechaDePago = moment(p.fechaDePago); return p})}} form={formModify}>
          <Row>
            <Col xl={12} xs={12}>
              <Form.Item label='Monto MMV' name='montoPetro'>
                <InputNumber
                  formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={(value) => (value ? value.replace(/Bs\s?|(,*)/g, '') : '')}
                />
              </Form.Item>
            </Col>
            <Col xl={12} xs={12}>
              <Form.Item label='Monto Bs' name='monto'>
                <InputNumber
                  formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={(value) => (value ? value.replace(/Bs\s?|(,*)/g, '') : '')}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col xl={12} xs={12}>
              <Form.Item label='Subtotal Bs' name='subtotal'>
                <InputNumber
                  formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={(value) => (value ? value.replace(/Bs\s?|(,*)/g, '') : '')}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col xl={12} xs={12}>
              <Form.Item label='Interes' name='interes'>
                <InputNumber
                  formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={(value) => (value ? value.replace(/Bs\s?|(,*)/g, '') : '')}
                />
              </Form.Item>
            </Col>
            <Col xl={12} xs={12}>
              <Form.Item label='Recargo' name='recargo'>
                <InputNumber
                  formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={(value) => (value ? value.replace(/Bs\s?|(,*)/g, '') : '')}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>   
            <Col xl={12} xs={12}>
              <Form.Item label='Monto Liquidado' name='montoliq'>
                <InputNumber
                  formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={(value) => (value ? value.replace(/Bs\s?|(,*)/g, '') : '')}
                />
              </Form.Item>
            </Col>
            <Col xl={12} xs={12}>
              <Form.Item label='Monto Recaudado' name='montoreq'>
                <InputNumber
                  formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={(value) => (value ? value.replace(/Bs\s?|(,*)/g, '') : '')}
                />
              </Form.Item>
            </Col>
          </Row>
          <Col xs={24} xl={12}>
            <Form.Item label='Fecha' name='fecha' rules={[{ required: false}]}>
              <DatePicker placeholder='Fecha' style={{ width: '100%' }} />
            </Form.Item>
          </Col>
          <Form.List name='pagos'>
          {(fields, { add, remove }) => (
          <div>
            {fields.map((field, index) => { 
               return (
            <Row gutter={24}>
              <Row>
                <Typography.Text>{`Pago ${index+1}`}</Typography.Text>
              </Row>
              <Row>
                <Col xs={12} xl={12}>
                  <Form.Item
                    label='Referencia'
                    name={[field.name, 'referencia']}
                    fieldKey={field.fieldKey}
                    rules={[{ required: true, message: 'Debe ingresar la referencia' }]}
                  >
                    <Input placeholder='Referencia' maxLength={30} disabled />
                  </Form.Item>
                </Col>
                <Col xs={12} xl={12}>
                  <Form.Item
                    label='Fecha de Pago'
                    name={[field.name, 'fechaDePago']}
                    fieldKey={field.fieldKey}
                    rules={[{ required: false}]}
                  >
                    <DatePicker placeholder='Fecha de Pago' style={{ width: '100%' }} />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col xs={12} xl={12}>
                  <Form.Item 
                    label='Monto'  
                    name={[field.name, 'monto']}
                    fieldKey={field.fieldKey}
                    rules={[{ required: false}]}>
                    <Input placeholder='Monto' />
                  </Form.Item>
                </Col>
              </Row>
            </Row>)})}
            </div>)}
          </Form.List>
        </Form>
      </Modal>
    </Card>
  );
};

const mapStateToProps = (state: State) => ({
  thm: state.thm,
  requests: state.req.requests,
  banks: state.bk.banks,
  institutions: state.inst.institutions,
  auth: state.auth,
});

export default connect(mapStateToProps, { changeRequestStatus, changeTaxData, rebateRequest })(PaySpecificTax);

interface PaySpecificTaxProps {
  thm: States.ThemeColors;
  requests: States.Requests['requests'];
  banks: States.Banks['banks'];
  institutions: States.Institutions['institutions'];
  auth: States.Auth;
  changeRequestStatus: (status: string, id: number) => void;
  changeTaxData: (id: number, data: Liquidacion) => void;
  rebateRequest: (req: Request) => void;
}
